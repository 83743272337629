import { BalloonBurner } from "./BalloonBurner";

const MAX_VELOCITY = -20;
export class HotAirBalloon extends Phaser.Events.EventEmitter {
  private scene!: Phaser.Scene;
  private thrust: number = 0;
  private burner = new BalloonBurner();
  private player!: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;
  public get fuelLevel() {
    return this.burner.fuelLevel;
  }
  public setScene(scene: Phaser.Scene) {
    this.scene = scene;
    this.burner.setScene(scene);
    this.burner.on("FUEL_LEVEL", (fuelLevel: number) => {
      this.emit("FUEL_LEVEL", fuelLevel);
    });
  }
  public preload() {
    this.burner.preload();
    this.scene.load.image("balloon", [
      "images/balloon.avif",
      "images/balloon.png",
    ]);
  }
  public create(spawnPosition: { x: number; y: number }) {
    this.player = this.scene.physics.add.sprite(
      spawnPosition.x,
      spawnPosition.y,
      "balloon"
    );
    this.player.setScale(0.4);
    this.player.setOrigin(0.5, 0.95);
    this.player.body.setVelocityY(-10);
    this.player.body.setGravityY(4);
    this.player.body.setCollideWorldBounds(true);
    // this.player.setDepth(1);
    this.burner.create(this.player, { x: 0, y: -10 });
  }
  public openBurnerValve() {
    this.burner.turnOn();
  }
  public closeBurnerValve() {
    this.burner.turnOff();
  }
  public update() {
    this.burner.update();
    if (this.burner.isActive) {
      const currentYVelocity = this.player.body.velocity.y;
      this.thrust = -0.25;
      const velocity = Math.max(currentYVelocity + this.thrust, MAX_VELOCITY);
      this.player.body.setVelocityY(velocity);
      // Some random lateral interference
      const xThrust = (Math.random() - 0.5) * 0.5;
      this.player.body.setVelocityX(this.player.body.velocity.x + xThrust);
    }
  }
}
