export type CallbackT<T> = (value: T) => unknown;

export class WatchedValue<T> {
  private _value: T;
  private _whenChanged: CallbackT<T> | undefined;
  public constructor(value: T, whenChanged?: CallbackT<T>) {
    this._value = value;
    this._whenChanged = whenChanged;
  }
  public get value() {
    return this._value;
  }
  public set value(v: T) {
    if (this._value !== v) {
      this._value = v;
      this._whenChanged?.(this._value);
    }
  }
  public get whenChanged(): CallbackT<T> | undefined {
    return this._whenChanged;
  }
  public set whenChanged(whenChanged: CallbackT<T> | undefined) {
    this._whenChanged = whenChanged;
  }
}
