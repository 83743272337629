export type Callback = () => unknown;

export class SmartBoolean {
  private isOn = false;
  public constructor(
    private initiallyTurnedOn = false,
    public whenStarted: Callback = () => {},
    public whenStopped: Callback = () => {}
  ) {
    this.isOn = initiallyTurnedOn;
  }
  public turnOn() {
    if (!this.isOn) {
      this.isOn = true;
      this.whenStarted?.();
    }
  }
  public turnOff() {
    if (this.isOn) {
      this.isOn = false;
      this.whenStopped?.();
    }
  }
  public toggle() {
    this.isOn = !this.isOn;
    if (this.isOn) {
      this.whenStarted?.();
    } else {
      this.whenStopped?.();
    }
  }
  public get isTurnedOn() {
    return this.isOn;
  }
}
