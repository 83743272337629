import * as Phaser from "phaser";
import * as Scale from "./Scaling";
import HelloWorldScene from "./TestScene";

const GameDiv = "gamewindow";
const GameDivId = "#gamewindow";

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  parent: GameDiv,
  width: Scale.gameUnitsX,
  height: Scale.gameUnitsY,
  physics: { default: "arcade" },
  scene: [HelloWorldScene],
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.Center.CENTER_HORIZONTALLY,
  },
};

const removeAllGameWindows = () => {
  const root = document.querySelector(GameDivId);
  while (root?.firstChild) {
    root.removeChild(root.firstChild);
  }
};

const isHotReloading = document.querySelector(GameDivId)?.hasChildNodes();
if (isHotReloading) {
  removeAllGameWindows();
  new Phaser.Game(config);
} else {
  window.addEventListener("load", () => {
    new Phaser.Game(config);
  });
}
