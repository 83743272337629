import { WatchedValue } from "./WatchedValue";
import { SmartBoolean } from "./SmartBoolean";

const MAX_FUEL = 200;
const FUEL_BURN_RATE = 1;
const FUEL_RECOVERY_RATE = 2;
const MISSING_SCENE_ERROR_MESSAGE = "Did you forget to call setScene()?";

export type BalloonBurnerEvent =
  | "FUEL_LEVEL"
  | "BURNER_ON"
  | "BURNER_OFF"
  | "VALVE_ON"
  | "VALVE_OFF";

export class BalloonBurner extends Phaser.Events.EventEmitter {
  private scene?: Phaser.Scene;
  private _fuelLevel = new WatchedValue(MAX_FUEL);
  private burnerValve = new SmartBoolean();
  private burnerFlame = new SmartBoolean();
  public setScene(scene: Phaser.Scene) {
    this.scene = scene;
  }
  public preload() {
    if (!this.scene) throw new Error(MISSING_SCENE_ERROR_MESSAGE);
    this.scene.load.image("flame", "images/flame1.png");
    this.scene.load.audio("burner-start", "audio/burner-start.wav");
    this.scene.load.audio("burner-middle", "audio/burner-middle.wav");
    this.scene.load.audio("burner-end", "audio/burner-end.wav");
  }
  public get fuelLevel() {
    return this._fuelLevel.value;
  }
  public get isActive() {
    return this.burnerValve.isTurnedOn && this.burnerFlame.isTurnedOn;
  }
  public create(
    follow: Phaser.GameObjects.GameObject,
    followOffset = { x: 0, y: 0 }
  ) {
    if (!this.scene) throw new Error(MISSING_SCENE_ERROR_MESSAGE);
    this._fuelLevel.whenChanged = () => {
      this.emit("FUEL_LEVEL", this._fuelLevel.value);
    };
    const flameParticles = this.scene.add.particles("flame");
    const flameParticleEmitter = flameParticles.createEmitter({
      on: false,
      speed: 5,
      scale: { start: 0.1, end: 0.4 },
      alpha: { start: 1, end: 0 },
      blendMode: "ADD",
      accelerationY: -300,
      rotate: { min: -120, max: -200 },
      lifespan: { min: 100, max: 500 },
      follow,
      followOffset,
    });
    this.burnerFlame.whenStarted = () => {
      flameParticleEmitter.start();
      this.scene?.sound.play("burner-start");
      this.scene?.sound.play("burner-middle", { delay: 0.5 });
      this.emit("BURNER_ON");
    };
    this.burnerFlame.whenStopped = () => {
      flameParticleEmitter.stop();
      this.scene?.sound.stopByKey("burner-start");
      this.scene?.sound.stopByKey("burner-middle");
      this.scene?.sound.play("burner-end");
      this.emit("BURNER_OFF");
    };
    this.burnerValve.whenStarted = () => {
      this.burnerFlame.turnOn();
      this.emit("VALVE_ON");
    };
    this.burnerValve.whenStopped = () => {
      this.burnerFlame.turnOff();
      this.emit("VALVE_OFF");
    };
  }
  public update() {
    if (this.burnerValve.isTurnedOn) {
      if (this.burnerFlame.isTurnedOn && this._fuelLevel.value > 0) {
        this._fuelLevel.value = Math.max(
          0,
          this._fuelLevel.value - FUEL_BURN_RATE
        );
        if (this._fuelLevel.value <= 0) {
          this.burnerFlame.turnOff();
        }
      }
    } else {
      this._fuelLevel.value = Math.min(
        this._fuelLevel.value + FUEL_RECOVERY_RATE,
        MAX_FUEL
      );
    }
  }
  public turnOn() {
    if (this._fuelLevel.value > 0) {
      this.burnerValve.turnOn();
    }
  }
  public turnOff() {
    this.burnerValve.turnOff();
  }
}
