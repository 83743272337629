import * as Phaser from "phaser";
import { HotAirBalloon } from "./HotAirBalloon";

const fitHeight = (obj: Phaser.GameObjects.Image, scene: Phaser.Scene) => {
  obj.setScale(scene.cameras.default.displayHeight / obj.height);
};

export default class TestScene extends Phaser.Scene {
  private readonly balloon: HotAirBalloon;
  constructor() {
    super("TestScene");
    this.balloon = new HotAirBalloon();
  }
  preload() {
    this.load.image("sky", "images/wide-background.jpg");
    this.balloon.setScene(this.scene.scene);
    this.balloon.preload();
  }
  create() {
    const backgroundImage = this.add.image(0, 0, "sky");
    backgroundImage.setOrigin(Math.random() / 2, 0);
    fitHeight(backgroundImage, this);
    this.balloon.create({ x: 250, y: 300 });
    this.input.on("pointerdown", (pointer: any) => {
      this.balloon.openBurnerValve();
    });
    this.input.on("pointerup", (pointer: any) => {
      this.balloon.closeBurnerValve();
    });
    this.add.text(5, 5, "Left Mouse for Fire!", {
      fontSize: 15 as any,
      color: "black",
    });
    const fuelText = this.add.text(5, 25, `Fuel: ${this.balloon.fuelLevel}`, {
      fontSize: 20 as any,
      color: "black",
    });
    this.balloon.on("FUEL_LEVEL", (fuelLevel: number) => {
      fuelText.setText(`Fuel: ${fuelLevel}`);
    });
  }
  update() {
    this.balloon.update();
  }
}
